<template>
  <a-form :form="form" @submit.prevent="validateStudent">
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-6">
        <a-form-item label="School" :label-col="{ sm: 4, md: 6 }" :wrapper-col="{ xs: 24, sm: 20, md: 18 }">
          <a-select
            showSearch
            placeholder="Select a School"
            optionFilterProp="children"
            @change="handleChangeSchool"
            v-decorator="[
              'school',
              { rules: [{ required: true, message: 'Please select a school!' }] },
            ]"
            :filterOption="filterOption">
              <a-select-option 
                v-for="school in schoolList"
                :key="school.id" 
                :value="school.id">
                  {{school.Name}}
              </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-form-item label="Campus" :label-col="{ sm: 5, md: 6 }" :wrapper-col="{ xs: 24, sm: 19, md: 18 }">
          <a-select
            showSearch
            placeholder="Select a Campus"
            optionFilterProp="children"
            :disabled="campusList.length === 0"
            @change="handleChangeCampus"
            v-decorator="[
                'Campus',
                { rules: [{ required: true, message: 'Please select a campus!' }] },
              ]"
            :filterOption="filterOption">
            <a-select-option 
              v-for="campus in campusList"
              :key="campus.id"
              :value="campus.id">
                {{campus.Name}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-form-item label="Class" :label-col="{ sm: 4, md: 6 }" :wrapper-col="{ xs: 24, sm: 20, md: 18 }">
          <a-select
          showSearch
          placeholder="Select a Class"
          optionFilterProp="children"
          :disabled="classList.length === 0"
          @change="handleChangeClass"
          v-decorator="[
              'Class',
              { rules: [{ required: true, message: 'Please select a class!' }] },
            ]"
          :filterOption="filterOption">
            <a-select-option 
              v-for="item in classList"
              :key="item.id"
              :value="item.id">
                {{item.Name}}
            </a-select-option>
         </a-select>
        </a-form-item>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-form-item label="Branch" :label-col="{ sm: 4, md: 6 }" :wrapper-col="{ xs: 24, sm: 20, md: 18 }">
          <a-select
            showSearch
            placeholder="Select a Branch"
            optionFilterProp="children"
            :disabled="branchList.length === 0"
            v-decorator="[
                'BranchID',
                { rules: [{ required: true, message: 'Please select a branch!' }] },
              ]"
            :filterOption="filterOption">
            <a-select-option 
              v-for="brach in branchList"
              :key="brach.id"
              :value="brach.id">
                {{brach.Name}}
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-form-item label="First Name" :label-col="{ sm: 6, md: 8, lg: 6 }" :wrapper-col="{ xs: 24, sm: 18, md: 16, lg: 18 }">
          <a-input placeholder="First Name" v-decorator="['Name', { rules: [{ required: true, message: 'Please input a first name!'}] }]"></a-input>
        </a-form-item>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-form-item label="Last Name" :label-col="{ sm: 6, md: 8, lg: 6 }" :wrapper-col="{ xs: 24, sm: 18, md: 16, lg: 18 }">
          <a-input placeholder="Last Name" v-decorator="['LastName', { rules: [{ required: true, message: 'Please input a last name!'}] }]"></a-input>
        </a-form-item>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-form-item label="Email" :label-col="{ sm: 4, md: 6 }" :wrapper-col="{ xs: 24, sm: 20, md: 18 }">
          <a-input placeholder="Email" v-decorator="['Email', { rules: [{ required: true, message: 'Please input a email!'}] }]"></a-input>
        </a-form-item>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-form-item label="Username" :label-col="{ sm: 5, md: 8, lg: 6 }" :wrapper-col="{ xs: 24, sm: 19, md: 16, lg: 18 }">
          <a-input placeholder="Username" v-decorator="['UserName', { rules: [{ required: true, message: 'Please input a last username!'}] }]"></a-input>
        </a-form-item>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-form-item label="Password" :label-col="{ sm: 5, md: 8, lg: 6 }" :wrapper-col="{ xs: 24, sm: 19, md: 16, lg: 18 }">
          <a-input placeholder="Password" v-decorator="['Password', { rules: [{ required: true, message: 'Please input a password!'}] }]"></a-input>
        </a-form-item>
      </div>
      <div class="col-sm-12 col-md-6">
        <a-form-item label="Status" :label-col="{ sm: 4, md: 6 }" :wrapper-col="{ xs: 24, sm: 20, md: 18 }">
          <a-select
            placeholder="Select a Status"
            v-decorator="[
                'Status',
                { initialValue: 1 },
                { rules: [{ required: true, message: 'Please select a status!' }] },
              ]">
            <a-select-option :value="1">
                Active
            </a-select-option>
            <a-select-option :value="0">
                Deleted
            </a-select-option>
          </a-select>
        </a-form-item>
      </div>
    </div>
    <div class="row justify-content-center">
      <div class="col-sm-12 col-md-2 text-center">
        <a-button htmlType="submit" type="primary" shape="round" :block="true">Add</a-button>
      </div>
    </div>
  </a-form>
</template>
<script>
import campusService from '@/services/campus';
import branchService from '@/services/branch';
import classService from '@/services/class';
import studentService from '@/services/student';

export default {
  props: ['schoolList'],
  data(){
    return {
      campusList: [],
      classList: [],
      branchList: [],
    }
  },
  beforeCreate() {
    this.form = this.$form.createForm(this, { name: 'student' });
  },
  methods: {
    async handleChangeSchool(schoolId) {
      this.campusList = [];
      this.classList = [];
      this.branchList = [];
      const result = await campusService.getCampusList(schoolId);
      this.campusList = result;
    },
    async handleChangeCampus(campusId) {
      this.classList = [];
      this.branchList = [];
      const result = await classService.getClassList(campusId);
      this.classList = result;
    },
    async handleChangeClass(classId) {
      this.branchList = [];
      const result = await branchService.getBranchList(classId);
      this.branchList = result;
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
    validateStudent() {
      this.form.validateFields( (err, values) => {
        if (!err) {
          this.createStudent(values);
        }
      });
    },
    async createStudent(values) {
      const result = await studentService.createStudent(values);
      if(result) {
        this.$message.success('Student Created!');
        this.form.resetFields();
      }
    }
  }
}
</script>
<style scoped>

</style>