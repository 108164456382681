<template>
  <div class="container-sm container-md" id="student">
    <div class="card">
      <a-tabs defaultActiveKey="1">
        <a-tab-pane key="1">
          <span slot="tab">
            <a-icon type="bars" />List
          </span>
          <student-list :schoolList="schoolList"></student-list>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">
            <a-icon type="plus" />Create
          </span>
          <create-student :schoolList="schoolList"></create-student>
        </a-tab-pane>
      </a-tabs>
    </div>
  </div>
</template>
<script>
import StudentList from '@/components/Student/List.vue';
import CreateStudent from '@/components/Student/Create.vue';
import schoolService from '@/services/school';

export default {
  components: {
    StudentList,
    CreateStudent
  },
  data(){
    return {
      schoolList: [],
    }
  },
  created(){
    this.setSchoolList();
    this.$store.commit('setActiveNavText', '5');
  },
  methods: {
    async setSchoolList() {
      const result = await schoolService.getSchoolList();
      this.schoolList = result;
    },
  }
}
</script>
<style scoped>
.card {
  box-shadow: 0 0px 3px rgba(0,0,0,0.12), 0 0px 2px rgba(0,0,0,0.24);
  margin-top: 20px;
  padding: 30px;
}
</style>